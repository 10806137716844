import { compose, nest } from 'core/libs/recompose';

import bindProps from 'core/components/bindProps';

import Ad from 'core/components/Ad';

import sponsoredStyles from 'core/components/Ad/styles/sponsoredStyles';
import card3Type7Styles from './styles/card3Type7';
import ListingSpecStyles from './styles/ListingSpec';
import ListingSpecSearchStyles from './styles/ListingSpecSearch';

import { NegativeMobile } from 'core/components/Wrappers';

/**
 * У мобильных баннеров нужно указывать мобильный `siteId` (`begun-auto-pad`).
 */
const commonProps = {
  siteId: '437066928',
  height: 250,
};

const listingSpecProps = bindProps({
  ...commonProps,
  name: 'Listing Spec (COM)',
  lazy: true,
  blockId: '460210208',
  options: {
    p1: 'bxuvr',
    p2: 'fqbd',
    pct: 'a',
  },
});

export const TopBanner = bindProps({
  ...commonProps,
  name: 'TopBanner',
  blockId: '437066968',
  options: {
    p1: 'btdzc',
    p2: 'fexc',
    pct: 'a',
  },
})(Ad);

export const Fullscreen = bindProps({
  ...commonProps,
  name: 'Fullscreen',
  height: null,
  blockId: '437066936',
})(Ad);

export const Rich = bindProps({
  ...commonProps,
  name: 'Rich',
  height: null,
  blockId: '437066966',
  options: {
    p1: 'btdzi',
    p2: 'ffhi',
    pct: 'a',
  },
})(Ad);

export const Listing1 = bindProps({
  ...commonProps,
  name: 'Listing1 (COM)',
  lazy: true,
  blockId: '437736556',
  options: {
    p1: 'bweeb',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, Ad));

export const Listing2 = bindProps({
  ...commonProps,
  name: 'Listing2 (COM)',
  lazy: true,
  blockId: '437736566',
  options: {
    p1: 'bweec',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, Ad));

export const Listing3 = bindProps({
  ...commonProps,
  name: 'Listing3 (COM)',
  lazy: true,
  blockId: '437736578',
  options: {
    p1: 'bweed',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, Ad));

export const Listing4 = bindProps({
  ...commonProps,
  name: 'Listing4 (COM)',
  lazy: true,
  blockId: '437736580',
  options: {
    p1: 'bweee',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, Ad));

export const ListingSpec = compose(
  listingSpecProps,
  ListingSpecStyles,
)(nest(NegativeMobile, Ad));

export const ListingSpecSearch = compose(
  listingSpecProps,
  ListingSpecSearchStyles,
)(nest(NegativeMobile, Ad));

export const Footer = bindProps({
  ...commonProps,
  name: 'Footer (COM)',
  lazy: true,
  blockId: '437736590',
  options: {
    p1: 'bweej',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, Ad));

export const Content1 = bindProps({
  ...commonProps,
  name: 'Content1 (COM)',
  lazy: true,
  blockId: '437736582',
  options: {
    p1: 'bweef',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, Ad));

export const Content2 = bindProps({
  ...commonProps,
  name: 'Content2 (COM)',
  lazy: true,
  blockId: '437736584',
  options: {
    p1: 'bweeg',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, Ad));

export const Content3 = bindProps({
  ...commonProps,
  name: 'Content3 (COM)',
  lazy: true,
  blockId: '437736586',
  options: {
    p1: 'bweeh',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, Ad));

export const Content4 = bindProps({
  ...commonProps,
  name: 'Content4 (COM)',
  lazy: true,
  blockId: '437736588',
  options: {
    p1: 'bweei',
    p2: 'emil',
    pct: 'a',
  },
})(nest(NegativeMobile, Ad));

export const ContentSpec = compose(
  bindProps({
    ...commonProps,
    name: 'Content Spec (COM)',
    lazy: true,
    blockId: '460210656',
    options: {
      p1: 'bxuvu',
      p2: 'fqbd',
      pct: 'a',
    },
  }),
  card3Type7Styles
)(nest(NegativeMobile, Ad));

export const InPage = bindProps({
  ...commonProps,
  name: 'InPage',
  height: 200,
  blockId: '437066964',
  options: {
    p1: 'btdzg',
    p2: 'fexd',
    pct: 'a',
  },
})(nest(NegativeMobile, Ad));

export const Sponsored = compose(
  bindProps({
    ...commonProps,
    name: 'Sponsored (COM)',
    height: null,
    blockId: '576322057',
  }),
  sponsoredStyles,
)(Ad);

export const InGallery = bindProps({
  ...commonProps,
  name: 'InGallery',
  blockId: '579389986',
  disableAutoInit: true,
  height: null,
})(Ad);
