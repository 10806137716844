import { withBreakpoint } from 'core/components/breakpoint';
import Exchange from 'core/components/Exchange';

import { PartnerWrapper } from 'site/components/Wrappers';


export { default as Recommender } from './Recommender';

export function Smi2Vertical() {
  return (
    <Exchange
      pageType='desktop_not_topics'
      blockType='vertical'
    />
  );
}

export function PartnersVertical() {
  return (
    <PartnerWrapper>
      <Exchange
        pageType='desktop_topics'
        blockType='vertical'
      />
    </PartnerWrapper>
  );
}

export const PartnersHorizontal = withBreakpoint(({ breakpoint }) => {
  return (
    <Exchange
      pageType={`${breakpoint}_topics`}
      blockType='horizontal'
    />
  );
});
